<template>
  <div>
<SubHeader :link1="{ name: 'User'}" :link1Name="$t('Users')" :linkActiveName="$t('Edit')" :linkB1="{ name: 'User_edit',params: { id: this.$route.params.id }  }" :linkB1Name="$t('Back')" 

/>
<DoneAlert :msg="$t('Data has been updated')" :visible="ShowDoneAlert" />
 <ErrorAlert :msg="errorMgs" :visible="ShowError" />
 <b-container  class=" mt-5" >

<FormSkeleton  v-if="!showForm" />
<b-card bg-variant="light" v-if="showForm" :header="form.name +' '+ $t('Permissions') " class="text-left">
<b-row>
    <b-col cols="12"  >

    <b-form-group label="" v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="selected"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="flavour-1"
      ></b-form-checkbox-group>
    </b-form-group>

     </b-col>
</b-row>
<b-row>
    <b-col cols="12"  >
    <b-button @click="onSubmit" variant="info"><b-spinner v-if="isSendingForm" small></b-spinner>  {{$t('Save')}}</b-button>
     </b-col>
</b-row>
    <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>
       </b-card>
</b-container>

</div>
</template>

<script>

import SubHeader from '@/components/layout/SubHeader.vue'
import axios from 'axios'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import FormSkeleton from '@/components/skeletons/FormSkeleton.vue'
  export default {
    data() {
      return {
             form: {
            name: null,
            last_name: null,
            email: null,
            phone:null,
            password: null,
            confirm_password:null,
            status:null,
            image:null,
            role:null,
            lang_id:this.$i18n.locale,
            user_id:this.$route.params.id,
            permissions: "",
           

        },
       // Must be an array reference!
        selected:[],
        options: [  ],
         main_id:this.$route.params.id,
        errorsBack:{},
        errorMgs:'',
        ShowError: false,
        show: true,
        ShowDoneAlert:false,
        isSendingForm:false,
        showForm:false,
      }
    },
 methods: {
     fillForm(Thedata){
        this.form.name = Thedata.name;
        this.form.last_name = Thedata.last_name;
        this.form.email = Thedata.email;
        this.form.phone = Thedata.phone;
        this.form.status = Thedata.status;
        this.form.role = Thedata.role;
        if(Thedata.thumbnailmd){  this.previewImage =this.$store.state.backendUrl+Thedata.thumbnailmd;}
      
        this.main_id= Thedata.id;

    //console.log("zxcxz-----------"+ this.form);


      },
      fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
     fixingSelected(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] =json[index].name ;
            
          }
           return array;
        },   
    getCreateData(){
      axios.get(this.$store.state.backendUrl+'api/user/'+this.$route.params.id+'/edit' , {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                if(response.Error){this.ShowError=true;this.errorMgs = this.$t('error_login');}
                else{
                 this.options = this.fixingOption (response.data.success.permissions);
                 this.selected = this.fixingSelected (response.data.success.user_permissions);
                 console.log( response.data.success.user_permissions)
                  this.fillForm(response.data.success.user);
                  this.showForm=true;
               //  console.log(response.data.success);
                }
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
       onSubmit(event) {
        event.preventDefault()
      

        this.isSendingForm = true;
        //alert(JSON.stringify(this.form))
        this.form.permissions = JSON.stringify(this.selected);
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
      
        axios.post(this.$store.state.backendUrl+'api/permissionUser',formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  setTimeout(()=>{     
                  this.ShowDoneAlert = false;
                  }, 3000);
                  this.getCreateData();
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
           
      },
 },
 /******************* */
       components:{
        SubHeader, DoneAlert,FormSkeleton,ErrorAlert
        
    },
    mounted() {
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
    }
  }
</script>